import React, {
  createContext,
  useContext,
  useRef,
  useState,
  useCallback,
} from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalStack, setModalStack] = useState([]);

  const pushModal = useCallback((modalRef) => {
    setModalStack((stack) => [...stack, modalRef]);
  }, []);

  const popModal = useCallback(() => {
    setModalStack((stack) => stack.slice(0, -1));
  }, []);

  const getTopModal = useCallback(() => {
    return modalStack[modalStack.length - 1];
  }, [modalStack]);

  return (
    <ModalContext.Provider value={{ pushModal, popModal, getTopModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
