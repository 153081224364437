import React, { useState } from 'react';
import ModalLayout from '../ModalLayout';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';
import {
  createTrigger,
  updateTrigger,
  deleteTrigger,
} from '../../../api/trigger';

const DeviceTrigger = ({ triggers, closeModal, deviceId }) => {
  const initialElements = triggers
    ? triggers.map((trigger) => ({
        ...trigger,
        messageTemplate: trigger.message_template,
      }))
    : [];

  const [elements, setElements] = useState(initialElements);
  const [type, setType] = useState('TG');
  const [condition, setCondition] = useState('');
  const [messageTemplate, setMessageTemplate] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const handleAddElement = async () => {
    if (!condition || !messageTemplate) return;

    try {
      if (editingIndex !== null) {
        const updatedTrigger = await updateTrigger(
          deviceId,
          elements[editingIndex].id,
          type,
          condition,
          messageTemplate
        );
        const updatedElements = [...elements];
        updatedElements[editingIndex] = {
          ...updatedTrigger,
          messageTemplate: updatedTrigger.message_template,
        };
        setElements(updatedElements);
        setEditingIndex(null);
      } else {
        const createdTrigger = await createTrigger(
          deviceId,
          type,
          condition,
          messageTemplate
        );
        setElements([
          ...elements,
          {
            ...createdTrigger,
            messageTemplate: createdTrigger.message_template,
          },
        ]);
      }
      resetForm();
    } catch (error) {
      console.error('Failed to save trigger:', error);
    }
  };

  const handleEditElement = (index) => {
    const element = elements[index];
    setType(element.type);
    setCondition(element.condition);
    setMessageTemplate(element.messageTemplate);
    setEditingIndex(index);
  };

  const handleDeleteElement = async (index) => {
    try {
      const triggerId = elements[index].id;
      await deleteTrigger(deviceId, triggerId);
      const updatedElements = elements.filter((_, i) => i !== index);
      setElements(updatedElements);
    } catch (error) {
      console.error('Failed to delete trigger:', error);
    }
  };

  const resetForm = () => {
    setType('TG');
    setCondition('');
    setMessageTemplate('');
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div
        className="p-6 bg-white rounded-lg shadow-lg"
        onClick={handleModalClick}
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold text-gray-800">
            Конфигурация нотификаций
          </h2>
          <a
            className="text-blue-600 hover:text-blue-800 text-lg font-semibold"
            href="/docs"
          >
            Подробнее
          </a>
        </div>
        <hr className="mb-6 border-gray-200" />
        <div className="space-y-4">
          <div className="grid grid-cols-[1fr_1fr_1fr_auto] gap-4 pb-2 font-semibold text-gray-700">
            <div>Тип</div>
            <div>Условие</div>
            <div>Шаблон сообщения</div>
            <div>Действия</div>
          </div>
          {elements.length > 0 ? (
            elements.map((element, index) => (
              <div
                key={element.id}
                className="grid grid-cols-[1fr_1fr_1fr_auto] items-center gap-4 py-3 border-b border-gray-100 hover:bg-gray-50 transition-colors"
              >
                <div className="text-gray-800">{element.type}</div>
                <div className="text-gray-800">{element.condition}</div>
                <div className="text-gray-800">{element.messageTemplate}</div>
                <div className="flex gap-2">
                  <button
                    onClick={() => handleEditElement(index)}
                    className="text-gray-500 hover:text-blue-600 transition-colors"
                    title="Редактировать"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteElement(index)}
                    className="text-gray-500 hover:text-red-600 transition-colors"
                    title="Удалить"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="grid grid-cols-[1fr_1fr_1fr_auto] gap-4 py-3 text-gray-400">
              <div>—</div>
              <div>—</div>
              <div>—</div>
              <div>—</div>
            </div>
          )}
        </div>
        <div className="mt-6 flex flex-col justify-between lg:flex-row gap-4">
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full lg:w-1/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="TG">Telegram</option>
          </select>
          <input
            type="text"
            value={condition}
            onChange={(e) => setCondition(e.target.value)}
            placeholder="D3 == 1"
            className="w-full lg:w-1/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="text"
            value={messageTemplate}
            onChange={(e) => setMessageTemplate(e.target.value)}
            placeholder="Нажали D3"
            className="w-full lg:w-1/4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={handleAddElement}
            className={`w-full lg:w-auto p-2 text-white rounded-lg transition-colors ${
              editingIndex !== null
                ? 'bg-green-500 hover:bg-green-600'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {editingIndex !== null ? 'Обновить' : 'Добавить'}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
};

export default DeviceTrigger;
