export const uploadFirmware = async (device_id, firmware) => {
  if (firmware) {
    const input = document.querySelector('input[type="file"]');
    let formData = new FormData();
    formData.append('firmware', input.files[0]);

    try {
      const result = await fetch(
        `https://iopp.io/api/device/firmware/upload/${device_id}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: localStorage.getItem('access_token'),
          },
        }
      );

      const data = await result.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  }
};
