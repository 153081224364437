import { combineReducers, configureStore } from '@reduxjs/toolkit';
import firmware from '../firmware';
import device from '../device';
import modals from '../modals';
import user from '../user';

const rootReducer = combineReducers({
  firmware,
  device,
  modals,
  user,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
