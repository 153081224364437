import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  postDevices,
  fetchDevices as fetchDevicesApi,
  deleteDevice as deleteDeviceApi,
  getFirmware,
} from '../api/devices';
import useUserData from './useUserData';
import { setDevices, setDeviceId, setState } from '../redux/device';

export default function useDeviceManagement() {
  const dispatch = useDispatch();
  const { devices, deviceId, state } = useSelector((state) => state.device);
  const [isLoading, setIsLoading] = useState(true);
  const [firmwareInfo, setFirmwareInfo] = useState();
  const [file, setFile] = useState(null);
  const { refetchUserData } = useUserData();

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    setIsLoading(true);
    const devices = await fetchDevicesApi();
    dispatch(setDevices(devices));
    setIsLoading(false);
  };

  const addNewDevice = async (name, wifi, wifiPassword) => {
    await postDevices(name, wifi, wifiPassword);
    await fetchDevices();
    refetchUserData();
  };

  const removeDevice = async (deviceId) => {
    await deleteDeviceApi(deviceId);
    await fetchDevices();
  };

  const handleFileChange = (event) => {
    if (event.target.files) setFile(event.target.files[0]);
  };

  return {
    devices,
    deviceId,
    state,
    firmwareInfo,
    isLoading,
    addNewDevice,
    removeDevice,
    handleFileChange,
    file,
  };
}
