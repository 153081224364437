import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '../api/devices';
import { setInfo } from '../redux/user';

export default function useUserData() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.info);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserData();
        dispatch(setInfo(data));
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchData();
  }, []);

  const refetchUserData = async () => {
    try {
      const data = await getUserData();
      dispatch(setInfo(data));
    } catch (error) {
      console.error('Error refetching user data:', error);
    }
  };

  const logout = () => {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('websocket_channel');
    localStorage.removeItem('access_token');
    window.location = '/';
  };

  return { refetchUserData, userData, logout };
}
