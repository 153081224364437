import React, { useState } from 'react';
import ModalLayout from '../ModalLayout';
import image from '../../../assets/yandex.svg';
import { updateDevice } from '../../../api/devices';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid';

function DeviceYandex({ closeModal, deviceId }) {
  const [elements, setElements] = useState([]);
  const [name, setName] = useState('');
  const [type, setType] = useState('Button');
  const [editingIndex, setEditingIndex] = useState(null);

  const handleAddElement = () => {
    if (name && type) {
      if (editingIndex !== null) {
        const updatedElements = [...elements];
        updatedElements[editingIndex] = { name, type };
        setElements(updatedElements);
        setEditingIndex(null);
      } else {
        setElements([...elements, { name, type }]);
      }
      setName('');
      setType('Button');
      sendUpdateRequest([...elements, { name, type }]);
    }
  };

  const handleEditElement = (index) => {
    const element = elements[index];
    setName(element.name);
    setType(element.type);
    setEditingIndex(index);
  };

  const handleDeleteElement = (index) => {
    const updatedElements = elements.filter((_, i) => i !== index);
    setElements(updatedElements);
    sendUpdateRequest(updatedElements);
  };

  const sendUpdateRequest = async (updatedElements) => {
    try {
      await updateDevice(deviceId, { yandexConfig: updatedElements });
    } catch (error) {
      console.error('Failed to update device:', error);
    }
  };

  const handleModalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="ModalLayout space-y-5 p-4" onClick={handleModalClick}>
        <div className="flex-col justify-between">
          <h3 className="text-lg font-bold md:text-2xl">
            Конфигурация для приложения умного дома от Яндекса
          </h3>
          <img className="bg-gray-600" src={image} alt="image" />
        </div>
        <hr />
        <div className="space-y-4">
          <div className="grid grid-cols-[1fr_1fr_auto] gap-4 border-b pb-2 font-semibold">
            <div>Название поля из состояния устройства</div>
            <div>Тип</div>
            <div>Действия</div>
          </div>
          {elements.length > 0 ? (
            elements.map((element, index) => (
              <div
                key={index}
                className="grid grid-cols-[1fr_1fr_auto] items-center gap-4 border-b py-2"
              >
                <div>{element.name}</div>
                <div>{element.type}</div>
                <div className="flex gap-5">
                  <button
                    onClick={() => handleEditElement(index)}
                    className="text-gray-600 hover:text-blue-700"
                    title="Редактировать"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleDeleteElement(index)}
                    className="text-gray-600 hover:text-red-700"
                    title="Удалить"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))
          ) : (
            <>
              <div className="grid grid-cols-[1fr_1fr_auto] gap-4 border-b py-2">
                <div className="text-gray-400">—</div>
                <div className="text-gray-400">—</div>
                <div className="text-gray-400">—</div>
              </div>
            </>
          )}
        </div>
        <hr />
        <div className="flex flex-col justify-between space-x-0 space-y-2 lg:flex-row lg:space-x-2 lg:space-y-0">
          <div className="w-full">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Ключ в состоянии устройства"
              className="w-full border p-2"
            />
          </div>
          <div className="w-full">
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full border p-2"
            >
              <option value="Button">Кнопка</option>
              <option value="Range">Диапазон значений</option>
            </select>
          </div>
          <button
            onClick={handleAddElement}
            className={`w-full p-2 text-white lg:w-fit ${
              editingIndex !== null
                ? 'bg-green-500 hover:bg-green-600'
                : 'bg-blue-500 hover:bg-blue-600'
            }`}
          >
            {editingIndex !== null ? 'Обновить' : 'Добавить'}
          </button>
        </div>
      </div>
    </ModalLayout>
  );
}

export default DeviceYandex;
