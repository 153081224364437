import React from 'react';
import { useSelector } from 'react-redux';
import DeviceModal from '../../components/Modals/DeviceModal';
import FirmwareInfoModal from '../../components/Modals/FirmwareInfoModal';
import DeviceCreationModal from '../../components/Modals/DeviceCreationModal';
import EventLogModal from '../../components/Modals/EventLogModal';
import DeviceHistoryModal from '../../components/Modals/DeviceStateModal';
import useModalManagement from '../../hooks/useModalManagement';

const Modals = () => {
  const { modals, handleCloseModal } = useModalManagement();
  const { deviceId } = useSelector((state) => state.device);
  const { firmware } = useSelector((state) => state.firmware);
  return (
    <>
      {modals.deviceModal && (
        <DeviceModal
          closeDeviceModal={() => handleCloseModal('deviceModal')}
          deviceId={deviceId}
          firmwareInfo={firmware}
        />
      )}
      {modals.deviceCreationModal && (
        <DeviceCreationModal
          closeDeviceCreationModal={() =>
            handleCloseModal('deviceCreationModal')
          }
        />
      )}
      {modals.firmwareInfoModal && (
        <FirmwareInfoModal
          deviceId={deviceId}
          firmwareInfo={firmware}
          closeFirmwareInfoModal={() => handleCloseModal('firmwareInfoModal')}
        />
      )}
      {modals.eventLogModal && (
        <EventLogModal
          deviceId={deviceId}
          closeModal={() => handleCloseModal('eventLogModal')}
        />
      )}
      {modals.deviceHistoryModal && (
        <DeviceHistoryModal
          deviceId={deviceId}
          closeModal={() => handleCloseModal('deviceHistoryModal')}
        />
      )}
    </>
  );
};

export default Modals;
