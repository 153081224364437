import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import { postDevices } from '../../api/devices';
import { useDispatch } from 'react-redux';
import useDeviceManagement from '../../hooks/useDeviceManagement';
const DeviceCreationForm = ({ setName, setWifi, setWifiPassword }) => (
  <div id="wifiForm" className="mt-5 space-y-4 px-2 lg:px-0">
    <div>
      <label htmlFor="name" className="block font-medium text-gray-700">
        Название устройства:
      </label>
      <input
        type="text"
        id="name"
        name="name"
        required
        placeholder="My ESP8266"
        className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm "
        onChange={(e) => setName(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="wifiSSID" className="block font-medium text-gray-700">
        Wi-Fi SSID (название сети):
      </label>
      <input
        type="text"
        id="wifiSSID"
        name="wifiSSID"
        placeholder="Название локальной Wi-Fi сети"
        className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm "
        onChange={(e) => setWifi(e.target.value)}
      />
    </div>
    <div>
      <label htmlFor="wifiPassword" className="block font-medium text-gray-700">
        Wi-Fi Пароль:
      </label>
      <input
        type="text"
        id="wifiPassword"
        name="wifiPassword"
        placeholder="Пароль Wi-Fi"
        className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm "
        onChange={(e) => setWifiPassword(e.target.value)}
      />
    </div>
    <div>
      <span className="text-gray-600">
        Внимательно заполните название вашей локальной сети Wi-Fi и пароль от
        нее, иначе устройство не сможет подключиться к платформе.
      </span>
    </div>
  </div>
);

function DeviceCreationModal({ closeDeviceCreationModal }) {
  const [name, setName] = useState('');
  const [wifi, setWifi] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');
  const { addNewDevice } = useDeviceManagement();
  const create = () => {
    addNewDevice(name, wifi, wifiPassword);
    closeDeviceCreationModal();
  };
  return (
    <ModalLayout closeModal={closeDeviceCreationModal}>
      <DeviceCreationForm
        setName={setName}
        setWifi={setWifi}
        setWifiPassword={setWifiPassword}
      />
      <div className="w-full mt-4">
        <button
          type="button"
          className="btn-primary hover:bg-green-200"
          onClick={create}
        >
          Создать
        </button>
      </div>
    </ModalLayout>
  );
}

export default DeviceCreationModal;
