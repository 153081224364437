import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import { createOrder, fetchTransactions } from '../../api/payment';
import { WalletIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const SkeletonLoading = () => {
  return (
    <div className="space-y-4">
      {[...Array(4)].map((_, index) => (
        <div
          key={index}
          className="mb-4 transform rounded-md bg-white p-4 shadow-md"
        >
          <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
          <div className="h-6 bg-gray-200 rounded w-1/2"></div>
          <div className="h-4 bg-gray-200 rounded w-1/3 mt-2"></div>
        </div>
      ))}
    </div>
  );
};

const DepositModal = ({ closeModal }) => {
  const [amount, setAmount] = useState(0);
  const pay = async () => {
    if (amount > 0) {
      const { payment_url } = await createOrder(amount * 100);
      window.open(payment_url, '_blank');
      closeModal();
    } else {
      toast.warning('Некорректная сумма');
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="flex flex-col justify-between gap-5 lg:flex-row">
        <input
          type="number"
          id="amount"
          name="amount"
          required
          placeholder="Сумма"
          className="mt-1 block w-full rounded-md border border-gray-300 p-2 shadow-sm "
          onChange={(e) => {
            setAmount(e.target.value);
          }}
        />
        <button
          onClick={pay}
          className="w-full rounded-sm border  px-6 py-2 text-base font-semibold text-green-800 bg-emerald-200  hover:bg-green-300 transition-colors"
        >
          Перейти к оплате
        </button>
      </div>
    </ModalLayout>
  );
};

const BalanceModal = ({ closeModal, userData }) => {
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(4);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [activeView, setActiveView] = useState('credits');
  const [depositModal, setDepositModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setLoading(true);

      try {
        const response = await fetchTransactions(
          (currentPage - 1) * transactionsPerPage,
          transactionsPerPage,
          activeView
        );
        const { data, total_records } = response;
        setHistory(data);
        setTotalPages(Math.ceil(total_records / transactionsPerPage));
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError('Не удалось загрузить данные. Пожалуйста, попробуйте позже.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentPage, transactionsPerPage, activeView]);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderTransactionList = (transactions) => {
    if (error) {
      return <p className="text-sm text-red-500 sm:text-base">{error}</p>;
    }

    if (transactions.length === 0) {
      return <p className="text-sm text-gray-500 sm:text-base">Нет данных.</p>;
    }

    return transactions.map((entry, index) => (
      <div
        key={index}
        className="mb-4 transform rounded-md bg-white p-4 shadow-md"
      >
        <p className="text-sm font-medium text-gray-600 sm:text-base">
          {new Date(entry.date).toLocaleString('ru-RU')}
        </p>
        <p className="mt-1 text-lg font-semibold text-gray-900">
          {entry.amount} ₽
        </p>
        {entry.reason && (
          <p className="mt-2 text-sm text-gray-500">{entry.reason}</p>
        )}
      </div>
    ));
  };

  const handleViewSwitch = (view) => {
    setActiveView(view);
    setCurrentPage(1);
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="space-y-2 p-2 sm:p-4 min-h-[40dvh]">
        {/* Current Balance and Daily Consumption */}
        <div className=" bg-gray-100 p-4 shadow-sm">
          <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
            <p className="text-lg  sm:text-xl">
              Ваш баланс:{' '}
              <span className="text-gray-800 text-nowrap">
                {userData.balance} ₽
              </span>
            </p>
            <p className="text-lg  sm:text-xl">
              Потребление в сутки:{' '}
              <span className="text-gray-800 text-nowrap">
                {userData.consumption} ₽
              </span>
            </p>
            <button
              className="flex items-center justify-center gap-2 rounded-md bg-green-200  text-green-900 px-6 py-3 text-sm font-medium transition-colors hover:bg-green-300 sm:text-base"
              onClick={() => setDepositModal(true)}
            >
              <WalletIcon className="h-5 w-5" />
              Пополнить баланс
            </button>
          </div>
        </div>

        {/* Switcher */}
        <div className="w-full flex justify-center">
          <div className="relative flex w-full   bg-gray-100 p-2">
            <button
              onClick={() => handleViewSwitch('credits')}
              className={`w-full  px-6 py-2 text-base font-semibold text-gray-700 transition-colors ${
                activeView === 'credits'
                  ? 'text-gray-600'
                  : 'hover:text-gray-600'
              }`}
            >
              Пополнения
            </button>
            <button
              onClick={() => handleViewSwitch('debits')}
              className={`w-full  px-6 py-2 text-base font-semibold text-gray-700 transition-colors ${
                activeView === 'debits'
                  ? 'text-gray-600'
                  : 'hover:text-gray-600'
              }`}
            >
              Списания
            </button>
            {/* Sliding underline */}
            <div
              className={`absolute bottom-0 h-0.5 bg-gray-600 transition-all duration-300 ${
                activeView === 'credits' ? 'left-0 w-1/2' : 'left-1/2 w-1/2'
              }`}
            ></div>
          </div>
        </div>

        {/* History Section */}
        <div className="rounded-lg bg-white p-4 shadow-sm">
          {loading ? (
            <SkeletonLoading />
          ) : (
            <div className="space-y-4">{renderTransactionList(history)}</div>
          )}
        </div>

        {/* Pagination */}
        {totalPages > 1 && (
          <div className="mt-8 flex items-center justify-center space-x-4">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="rounded-lg bg-gray-600 px-4 py-2 text-sm font-semibold text-white transition-all hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Назад
            </button>
            <span className="text-sm text-gray-700">
              Страница {currentPage} из {totalPages}
            </span>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="rounded-lg bg-gray-600 px-4 py-2 text-sm font-semibold text-white transition-all hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Вперед
            </button>
          </div>
        )}
      </div>
      {depositModal && (
        <DepositModal closeModal={() => setDepositModal(false)} />
      )}
    </ModalLayout>
  );
};

export default BalanceModal;
