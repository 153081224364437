import React from 'react';
import { toast } from 'react-toastify';

function DeviceInfoTable({ device }) {
  const handleCopyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Скопировано в буфер обмена!');
      })
      .catch((err) => {
        console.error('Failed to copy text:', err);
      });
  };

  return (
    <div className="overflow-hidden rounded-lg border border-slate-200 shadow-sm">
      <table className="w-full">
        <tbody>
          <tr className="transition-colors hover:bg-gray-50">
            <td className="min-w-[120px] border-b border-slate-200 bg-zinc-50 p-3 font-semibold text-gray-700">
              MQTT_ID
            </td>
            <td
              className="cursor-pointer border-b border-slate-200 bg-white p-3 font-mono text-sm text-gray-900 hover:bg-sky-50"
              onClick={() => handleCopyToClipboard(device?.mqtt_id)}
            >
              {device?.mqtt_id}
            </td>
          </tr>

          <tr className="transition-colors hover:bg-gray-50">
            <td className="min-w-[120px] border-b border-slate-200 bg-zinc-50 p-3 font-semibold text-gray-700">
              MQTT_DEVICE
            </td>
            <td
              className="cursor-pointer border-b border-slate-200 bg-white p-3 font-mono text-sm text-gray-900 hover:bg-sky-50"
              onClick={() => handleCopyToClipboard(device?.id)}
            >
              {device?.id}
            </td>
          </tr>

          <tr className="transition-colors hover:bg-gray-50">
            <td className="min-w-[120px] border-b border-slate-200 bg-zinc-50 p-3 font-semibold text-gray-700">
              MQTT_PASSWORD
            </td>
            <td
              className="cursor-pointer border-b border-slate-200 bg-white p-3 font-mono text-sm text-gray-900 hover:bg-sky-50"
              onClick={() => handleCopyToClipboard(device?.mqtt_password)}
            >
              {device?.mqtt_password}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default DeviceInfoTable;
