import React from 'react';

function ESPInstall({ manifest_url }) {
  return (
    <esp-web-install-button manifest={manifest_url}>
      <button className="btn-primary" slot="activate">
        Установить прошивку через USB
      </button>
      <span slot="unsupported" className="text-sm text-red-500">
        Ваш браузер не поддерживается
      </span>
    </esp-web-install-button>
  );
}

export default ESPInstall;
