import { API_URL } from '../constants/constants';
import { fetch as fetchJSON } from './refreshToken.js';
import { toast } from 'react-toastify';

export async function fetchDevices() {
  try {
    const response = await fetchJSON(`${API_URL}/device/devices`, {
      method: 'GET',
    });
    return response.body.devices;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error fetching devices:', error);
    throw error;
  }
}

export async function getState(device_id) {
  try {
    const response = await fetchJSON(
      `${API_URL}/device/devices/${device_id}/state`,
      { method: 'GET' }
    );
    return response.body.state;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error fetching devices:', error);
    throw error;
  }
}

export async function getTarifs() {
  try {
    const response = await fetchJSON(`${API_URL}/tariffs`, { method: 'GET' });
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error fetching tariffs:', error);
    throw error;
  }
}

export async function getUserData() {
  try {
    const response = await fetchJSON(`${API_URL}/profile/info`, {
      method: 'GET',
    });
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error fetching user data:', error);
    throw error;
  }
}

export async function postDevices(name, wifi_ssid, wifi_password) {
  const device = { name, wifi_ssid, wifi_password };
  try {
    const response = await fetchJSON(`${API_URL}/device/devices`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;' },
      body: JSON.stringify(device),
    });
    return response.body.device_id;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error creating device:', error);
    throw error;
  }
}

export async function deleteDevice(device_id) {
  try {
    await fetchJSON(`${API_URL}/device/devices/${device_id}`, {
      method: 'DELETE',
    });
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error deleting device:', error);
    throw error;
  }
}

export async function deviceRunBuild(deviceId, libraries) {
  const data = { libraries };
  try {
    const response = await fetchJSON(`${API_URL}/device/firmware/${deviceId}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json;' },
      body: JSON.stringify(data),
    });
    return response.body.device_id;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error run build:', error);
    throw error;
  }
}

export async function deviceRunReBuild(deviceId, firmwareId) {
  try {
    const response = await fetchJSON(
      `${API_URL}/device/firmware/${deviceId}/${firmwareId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json;' },
      }
    );
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error run rebuild:', error);
    throw error;
  }
}

export async function getFirmware(device_id) {
  try {
    const response = await fetchJSON(
      `${API_URL}/device/firmware/${device_id}`,
      {
        method: 'GET',
      }
    );
    return response.body.firmwares;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error(error);
    throw error;
  }
}

export async function startOTA(firmwareId) {
  try {
    const response = await fetchJSON(`${API_URL}/device/ota/${firmwareId}`, {
      method: 'POST',
    });
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error(error);
    throw error;
  }
}

export async function getDeviceHistory(device_id) {
  try {
    const response = await fetchJSON(
      `${API_URL}/device/devices/${device_id}/history?from_ts=1&to_ts=1&limit=10&offset=0`,
      { method: 'GET' }
    );
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error(error);
    throw error;
  }
}

export async function uploadFile(value, device_id) {
  const blob = new Blob([value]);
  const formData = new FormData();
  formData.append('firmware', blob, 'code.ino');
  try {
    const response = await fetch(
      `${API_URL}/device/firmware/upload/${device_id}`,
      {
        method: 'POST',
        headers: { Authorization: localStorage.getItem('access_token') },
        body: formData,
      }
    );
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    toast.success('Файл сохранен');
    return response;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error uploading file:', error);
    throw error;
  }
}

export async function updateDeviceState(device_id, deviceState) {
  try {
    const response = await fetchJSON(
      `${API_URL}/device/devices/${device_id}/update_state`,
      {
        method: 'POST',
        body: JSON.stringify({ state: deviceState }),
      }
    );
    return response;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error(error);
    throw error;
  }
}

export async function updateDevice(deviceId, data) {
  try {
    const response = await fetchJSON(`${API_URL}/device/devices/${deviceId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    });
    return response;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error updating device:', error);
    throw error;
  }
}

export async function fetchHistory(deviceId, page, perPage, from_ts, to_ts) {
  let url = `${API_URL}/device/devices/${deviceId}/history?offset=${page}&limit=${perPage}`;
  if (from_ts) url += `&from_ts=${from_ts}`;
  if (to_ts) url += `&to_ts=${to_ts}`;
  try {
    const response = await fetchJSON(url);
    return response.body;
  } catch (error) {
    toast.error('Ошибка сети');
    console.error('Error fetching history:', error);
    throw error;
  }
}
