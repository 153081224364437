import React from 'react';

function Logo() {
  return (
    <svg viewBox="0 0 210 50" xmlns="http://www.w3.org/2000/svg">
      <rect x="0" y="0" width="10" height="10" fill="#000000"></rect>
      <rect x="0" y="20" width="10" height="20" fill="#000000"></rect>

      <rect x="20" y="10" width="10" height="30" fill="#000000"></rect>
      <rect x="20" y="10" width="30" height="10" fill="#000000"></rect>
      <rect x="40" y="10" width="10" height="30" fill="#000000"></rect>
      <rect x="20" y="30" width="30" height="10" fill="#000000"></rect>

      <rect x="60" y="10" width="10" height="50" fill="#000000"></rect>
      <rect x="60" y="10" width="30" height="10" fill="#000000"></rect>
      <rect x="60" y="30" width="30" height="10" fill="#000000"></rect>
      <rect x="80" y="10" width="10" height="30" fill="#000000"></rect>

      <rect x="100" y="10" width="10" height="50" fill="#000000"></rect>
      <rect x="100" y="10" width="30" height="10" fill="#000000"></rect>
      <rect x="100" y="30" width="30" height="10" fill="#000000"></rect>
      <rect x="120" y="10" width="10" height="30" fill="#000000"></rect>

      <rect x="140" y="30" width="10" height="10" fill="#00ff00"></rect>

      <rect x="160" y="0" width="10" height="10" fill="#000000"></rect>
      <rect x="160" y="20" width="10" height="20" fill="#000000"></rect>

      <rect x="180" y="10" width="10" height="30" fill="#000000"></rect>
      <rect x="180" y="10" width="30" height="10" fill="#000000"></rect>
      <rect x="200" y="10" width="10" height="30" fill="#000000"></rect>
      <rect x="180" y="30" width="30" height="10" fill="#000000"></rect>
    </svg>
  );
}

export default Logo;
