import { useDispatch, useSelector } from 'react-redux';
import { openModal, closeModal } from '../redux/modals';

export default function useModalManagement() {
  const dispatch = useDispatch();
  const modals = useSelector((state) => state.modals);

  const handleOpenModal = (modalName) => {
    dispatch(openModal(modalName));
  };

  const handleCloseModal = (modalName) => {
    dispatch(closeModal(modalName));
  };

  return {
    modals,
    handleOpenModal,
    handleCloseModal,
  };
}
