import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import {
  fetchNotifications,
  markNotificationsAsRead,
} from '../../api/notifications';
import useDeviceManagement from '../../hooks/useDeviceManagement';

const NotificationSkeleton = () => (
  <div className="mb-2 flex animate-pulse justify-between rounded-lg bg-gray-100 p-3">
    <div className="w-full">
      <div className="h-7 w-1/3 rounded bg-gray-300"></div>
      <div className="mt-2 h-4 w-2/3 rounded bg-gray-300"></div>
    </div>
    <div className="h-5 w-1/4 rounded bg-gray-300"></div>
  </div>
);

const EventLogModal = ({
  deviceId,
  closeModal,
  updateUnreadNotificationsCount,
}) => {
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [notificationsPerPage] = useState(6);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false); // Loading state

  const { devices } = useDeviceManagement();

  const get_device_name = (device_id) => {
    const device = devices.find((device) => device.id === device_id);
    return device ? device.name : null;
  };

  const fetchNotificationsData = async (page) => {
    setLoading(true);
    try {
      const response = await fetchNotifications(
        page * notificationsPerPage,
        notificationsPerPage,
        deviceId
      );
      setNotifications(response.data);
      setTotalPages(Math.ceil(response.total_records / notificationsPerPage));
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificationsData(currentPage - 1);
  }, [currentPage]);

  useEffect(() => {
    const unreadNotifications = notifications.filter((n) => !n.seen);
    if (unreadNotifications.length > 0) {
      const updatedNotifications = notifications.map((n) => ({
        ...n,
        seen: true,
      }));
      setTimeout(() => {
        setNotifications(updatedNotifications);
        markNotificationsAsRead(unreadNotifications.map((n) => n.id));
        updateUnreadNotificationsCount(
          updatedNotifications.filter((n) => !n.seen).length
        );
      }, 1000);
    }
  }, [notifications]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <ModalLayout closeModal={closeModal}>
      <h2 className="mb-4 text-xl font-bold text-gray-800">
        Журнал событий
        {deviceId ? ' устройства' : ' устройств'}
      </h2>
      <div className="p-6 min-h-[40dvh]">
        <div className="">
          {loading ? ( // Show skeleton loader while loading
            [...Array(notificationsPerPage)].map((_, index) => (
              <NotificationSkeleton key={index} />
            ))
          ) : notifications.length > 0 ? ( // Show actual notifications
            notifications.map((notification, index) => (
              <div
                key={index}
                className={`mb-2 flex justify-between rounded-lg p-3 transition-colors ${
                  notification.seen ? 'bg-gray-100' : 'bg-blue-100'
                }`}
              >
                <div>
                  <p className="text-xs">
                    {get_device_name(notification.device_id)} (
                    <span className="text-sm text-gray-400">
                      {notification.device_id}
                    </span>
                    )
                  </p>
                  <p className="text-base">{notification.message}</p>
                </div>
                <p className="mt-1 text-sm text-gray-500">
                  {new Date(notification.timestamp_ms).toLocaleString('ru-RU')}
                </p>
              </div>
            ))
          ) : (
            <p>Нет новых уведомлений.</p>
          )}
        </div>

        {totalPages > 1 && (
          <div className="mt-4 flex items-center justify-center space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1 || loading}
              className="rounded-md bg-gray-200 px-3 py-1 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Назад
            </button>
            <span className="text-sm">
              Страница {currentPage} из {totalPages}
            </span>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages || loading}
              className="rounded-md bg-gray-200 px-3 py-1 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Вперед
            </button>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default EventLogModal;
