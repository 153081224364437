import { API_URL } from '../constants/constants';
import { fetch } from './refreshToken.js';

export const fetchNotifications = async (page, perPage, deviceId) => {
  let url = `${API_URL}/device/notifications?offset=${page}&limit=${perPage}`;

  if (deviceId) {
    url += `&device_id=${deviceId}`;
  }

  const response = await fetch(url);
  const data = await response;
  return data.body;
};

export const markNotificationsAsRead = async (notificationIds) => {
  await fetch(`${API_URL}/device/notifications`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: notificationIds }),
  });
};
