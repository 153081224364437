import React, { useState } from 'react';
import ModalLayout from './ModalLayout';
import { useDispatch } from 'react-redux';
import { openModal } from '../../redux/modals';

function FirmwareBuildModal({ previousLibraries, runBuild, closeModal }) {
  const dispatch = useDispatch();
  const [libraries, setLibraries] = useState(previousLibraries || '');
  const [error, setError] = useState('');

  const repoPattern =
    /^https:\/\/github\.com\/[a-zA-Z0-9_.-]+\/[a-zA-Z0-9_.-]+\.git(?:#[a-zA-Z0-9_.-]+)?$/;

  const handleInputChange = (event) => {
    setLibraries(event.target.value);
    setError('');
  };

  const validateLibraries = (input) => {
    if (!input) {
      return true;
    }

    const repoStrings = input.split(/\s+/);

    for (const repo of repoStrings) {
      if (repo && !repoPattern.test(repo)) {
        setError(
          `Invalid repository format: ${repo}. Expected format: https://github.com/owner/repo.git#directory`
        );
        return false;
      }
    }

    return true;
  };

  const handleRunBuild = () => {
    if (validateLibraries(libraries)) {
      runBuild(libraries);
      closeModal();
      dispatch(openModal('firmwareInfoModal'));
    }
  };

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="p-6 bg-white rounded-lg shadow-lg">
        {/* Code Block */}
        <div className="mb-6 bg-gray-50 p-4 rounded-lg border border-gray-200">
          <pre className="text-sm text-gray-700 whitespace-pre-wrap break-words font-mono">
            <code>
              <span className="text-blue-600">arduino-cli compile</span> \<br />
              &nbsp;&nbsp;--fqbn esp8266:esp8266:nodemcu \<br />
              &nbsp;&nbsp;--library /build/components/ArduinoJson/src \<br />
              &nbsp;&nbsp;--library /build/components/pubsubclient/src \<br />
              &nbsp;&nbsp;
              <span className="text-gray-500">
                Если необходимо, построчно введите URL github библиотек, которые
                использует ваша прошивка
              </span>
            </code>
          </pre>
        </div>

        {/* Libraries Input */}
        <div className="mb-6">
          <textarea
            value={libraries}
            onChange={handleInputChange}
            placeholder="Например: https://github.com/Arduino-IRremote/Arduino-IRremote.git#src&#10;Где #src - путь до исходных файлов библиотеки"
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-y transition-all duration-200"
            rows={4}
          />
        </div>

        {/* Error Message */}
        {error && (
          <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        {/* Run Build Button */}
        <button
          onClick={handleRunBuild}
          className="w-full bg-gray-200 border border-gray-300 text-black font-medium px-4 py-2.5 rounded-sm hover:bg-gray-300 transition-all duration-100"
        >
          Запустить сборку
        </button>
      </div>
    </ModalLayout>
  );
}

export default FirmwareBuildModal;
