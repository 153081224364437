import { createSlice } from '@reduxjs/toolkit';

const modalsSlice = createSlice({
  name: 'user',
  initialState: {
    info: {},
  },
  reducers: {
    setInfo: (state, action) => {
      state.info = {
        ...state.info,
        ...action.payload,
      };
    },
  },
});

export const { setInfo } = modalsSlice.actions;
export default modalsSlice.reducer;
