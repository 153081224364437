export const API_URL = process.env.REACT_APP_API_URL;
export const WS_URL = process.env.REACT_APP_WS_URL;
export const BOT_NAME = process.env.REACT_APP_BOT_NAME;

export const accessToken = localStorage.getItem('access_token');

export const githubLightTheme = {
  main: 'line-height:1.5;color:#24292e;background:#ffffff;overflow:auto;white-space:pre;', // Disable tabulation
  error: 'line-height:1.5;color:#d73a49;background:#ffffff;overflow:auto;',
  key: 'color:#d73a49;',
  string: 'color:#032f62;',
  value: 'color:#005cc5;',
  boolean: 'color:#005cc5;',
  null: 'color:#6a737d;',
  undefined: 'color:#6a737d;',
};
