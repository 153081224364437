import React, { useEffect, useState } from 'react';
import ModalLayout from './ModalLayout';
import ReactJsonPretty from 'react-json-pretty';
import { fetchHistory } from '../../api/devices';
import { githubLightTheme } from '../../constants/constants';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

const DeviceHistoryModal = ({ deviceId, closeModal }) => {
  const [history, setHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(4); // Default items per page
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState(null); // Start datetime for filter
  const [toDate, setToDate] = useState(null); // End datetime for filter
  const [pageInput, setPageInput] = useState(''); // For manual page input

  // Sync pageInput with currentPage
  useEffect(() => {
    setPageInput(currentPage.toString());
  }, [currentPage]);

  // Format timestamp to a readable format
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const utcYear = date.getUTCFullYear();
    const utcMonth = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
    const utcDay = String(date.getUTCDate()).padStart(2, '0');
    const utcHours = String(date.getUTCHours()).padStart(2, '0');
    const utcMinutes = String(date.getUTCMinutes()).padStart(2, '0');
    const utcSeconds = String(date.getUTCSeconds()).padStart(2, '0');
    const utcMilliseconds = String(date.getUTCMilliseconds()).padStart(3, '0');

    return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}.${utcMilliseconds} UTC`;
  };

  // Fetch history data with optional time filter
  const fetchHistoryData = async (page, from_ts = null, to_ts = null) => {
    try {
      const response = await fetchHistory(
        deviceId,
        page * perPage,
        perPage,
        from_ts,
        to_ts
      );
      setHistory(response.data);
      setTotalPages(Math.ceil(response.total_records / perPage));
    } catch (error) {
      console.error('Error fetching history:', error);
    }
  };

  // Handle datetime range change
  const handleDateTimeRangeChange = (type, date) => {
    if (type === 'from') {
      setFromDate(date);
    } else if (type === 'to') {
      setToDate(date);
    }
  };

  // Handle pagination
  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Handle items per page change
  const handlePerPageChange = (event) => {
    const newPerPage = Number(event.target.value);
    setPerPage(newPerPage);
    setCurrentPage(1); // Reset to the first page when changing items per page
  };

  // Handle manual page input change
  const handlePageInputChange = (event) => {
    setPageInput(event.target.value);
  };

  // Handle manual page input submission
  const handlePageInputSubmit = () => {
    const pageNumber = parseInt(pageInput, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    } else {
      // Reset input if the value is invalid
      setPageInput(currentPage.toString());
    }
  };

  // Handle Enter key press in the page input
  const handlePageInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      handlePageInputSubmit();
    }
  };

  // Fetch history when currentPage, perPage, fromDate, or toDate changes
  useEffect(() => {
    fetchHistoryData(currentPage - 1, fromDate?.getTime(), toDate?.getTime());
  }, [currentPage, perPage, fromDate, toDate]);

  return (
    <ModalLayout closeModal={closeModal}>
      <div className="p-4 sm:p-6 bg-white rounded-lg shadow-lg">
        <h2 className="mb-4 text-xl font-bold text-gray-800">
          История состояний устройства
        </h2>

        {/* Datetime Range Picker */}
        <div className="mb-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                От:
              </label>
              <DateTimePicker
                onChange={(date) => handleDateTimeRangeChange('from', date)}
                value={fromDate}
                format="dd/MM/yyyy HH:mm:ss" // Include time in the format
                disableClock // Disable the clock popup
                clearIcon={null}
                className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              />
            </div>
            <div className="flex-1">
              <label className="block mb-2 text-sm font-medium text-gray-700">
                До:
              </label>
              <DateTimePicker
                onChange={(date) => handleDateTimeRangeChange('to', date)}
                value={toDate}
                format="dd/MM/yyyy HH:mm:ss" // Include time in the format
                disableClock // Disable the clock popup
                clearIcon={null}
                className="w-full rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
              />
            </div>
          </div>
        </div>

        {/* History Table */}
        <div className="overflow-x-auto rounded-lg border border-gray-200 shadow-sm">
          {history.length > 0 ? (
            <>
              {/* Table Header */}
              <div className="grid grid-cols-3 min-w-[600px] text-center bg-gradient-to-r from-blue-50 to-purple-50 p-3 font-semibold text-gray-700">
                <span>Время</span>
                <span>Топик</span>
                <span>Состояние</span>
              </div>

              {/* Table Rows */}
              {history.map((log, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 min-w-[600px] items-center border-b border-gray-100 bg-white p-3 hover:bg-gray-50 transition-colors"
                >
                  <span className="text-sm text-gray-600">
                    {formatTimestamp(log.timestamp_ms)}
                  </span>
                  <span className="text-sm text-gray-800">{log.topic}</span>
                  <ReactJsonPretty
                    id="currentStatus"
                    data={log.msg}
                    theme={githubLightTheme}
                    style={{ fontSize: '12px' }}
                    space="2"
                  />
                </div>
              ))}
            </>
          ) : (
            <div className="p-6 text-center text-gray-500">
              Нет истории состояний
            </div>
          )}
        </div>

        {/* Pagination and Items Per Page Dropdown */}
        <div className="mt-6 flex flex-col sm:flex-row items-center justify-between gap-3">
          {/* Items Per Page Dropdown */}
          <div className="flex items-center gap-2">
            <label className="text-sm text-gray-700">
              Элементов на странице:
            </label>
            <select
              value={perPage}
              onChange={handlePerPageChange}
              className="rounded-lg border border-gray-300 p-2 text-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={16}>16</option>
              <option value={32}>32</option>
            </select>
          </div>

          {/* Pagination Controls */}
          <div className="flex items-center gap-3">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1 || totalPages === 0} // Disable if on the first page or no pages
              className="rounded-lg bg-gray-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Назад
            </button>
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-700">
                Страница{' '}
                <input
                  type="number"
                  value={pageInput}
                  onChange={handlePageInputChange}
                  onKeyPress={handlePageInputKeyPress}
                  className="w-16 rounded-lg border border-gray-300 p-1 text-sm text-center focus:border-blue-500 focus:ring-2 focus:ring-blue-200"
                  min={1}
                  max={totalPages}
                />{' '}
                из {totalPages || 1}
              </span>
              <button
                onClick={handlePageInputSubmit}
                className="rounded-lg bg-blue-500 px-3 py-1 text-sm font-semibold text-white transition-colors hover:bg-blue-600"
              >
                Перейти
              </button>
            </div>
            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages || totalPages === 0} // Disable if on the last page or no pages
              className="rounded-lg bg-gray-500 px-4 py-2 text-sm font-semibold text-white transition-colors hover:bg-gray-800 disabled:cursor-not-allowed disabled:opacity-50"
            >
              Вперед
            </button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default DeviceHistoryModal;
